import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export function useBannerAnimation() {

  gsap.registerPlugin(ScrollTrigger);

  let mm = gsap.matchMedia();


  mm.add('(min-width: 1024px)', () => {
    const tween = gsap.to('#mainBannerCover', { y: '-100%', opacity: 0, duration: 5, ease: 'ease-out' });
    const headerHeight = document.querySelector<HTMLElement>('.sf-header__sticky').offsetHeight || null;

    ScrollTrigger.create({
      trigger: '#mainBanner',
      toggleActions: 'play none reverse none',
      start: () => '-=' + headerHeight + 'top',
      end: 'bottom 10%',
      scrub: true,
      pin: true,
      animation: tween
    });
  });


}

export default useBannerAnimation;
