export const CashmereQuery = `
 query  {
  products(
    filter: {
      sku: {
        in: [
          "20.04.40.0000.conf"
          "20.04.17.0078.conf"
          "1015.00027.conf"
          "1015.00042"
          "1015.00043"
          "1015.00064"
          "1015.00075"
          "1015.00065"
          "20.03.10.0104"
          "1015.00059"
          "20.03.10.0106"
          "1015.00031"
          "20.03.09.0089"
          "1015.00083"
          "20.03.10.0105"
          "1013.00271.conf"
          "1013.00276.conf"
          "1013.00281.conf"
          "1013.00271.conf"
          "1013.00276.conf"
          "1013.00281.conf"
          "1015.00059"
          "1015.00083"
          "1015.00075"
          "1029.00161"
          "1013.00684.conf"
          "1013.00689.conf"
          "1002.00224"
          "1002.00226"
          "1013.00286.conf"
          "1013.00292.conf"
          "1013.00266.conf"
        ]
      }
    }
    pageSize: 99
  ) {
    items {
      type_id
      tag_line
      name
      sku
      url_key
      url_suffix
      url_rewrites {
        url
      }
      image {
        url
        label
      }
      small_image {
        url
        label
      }
      stock_status
      price_range {
        minimum_price {
          final_price {
            value
            currency
          }
        }
      }
      name
      sku
    }
  }
}
`;
