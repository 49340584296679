<template>
  <div class="woolux">
    <div id="mainBanner">
      <section class="section-sky">
        <div class="section-sky__container">
          <SfHeading
            :level="2"
            :styleLevel="1"
            title="The Skies"
          />
          <p class="text">
            The Skies
            Tibet is sometimes referred to as ‘The Skies’ due to its mountainous landscapes. It is hard to breathe here
            because there's so little oxygen at this height, but the cleanliness and freshness of the air are unique.
            These epithets can be used to describe many things in this part of the world, including those majestic
            inhabitants that we are talking about here today.
          </p>
        </div>
      </section>
      <section id="mainBannerCover" class="section-cashmere">
        <div class="section-cashmere__container">
          <SfHeading
            :level="1"
            :styleLevel="1"
            title="Tibetan cashmere"
          />
          <p class="text">
            The harsh beauty and purity of the Himalayas are embodiedin its most ancient inhabitant, the Tibetan
            cashmere
            goat.
          </p>
        </div>
      </section>
    </div>
    <section class="section-perf">
      <div class="section-perf__container">
        <SfHeading
          :level="2"
          :styleLevel="2"
          title="THE PERFECTION OF NATURE BY TOGAS"
        />
      </div>
    </section>
    <section class="section-peaks">
      <div class="section-container left">
        <div class="section-container__text-wrapper">
          <div class="section-container__text">
            <SfHeading
              class="mw-240"
              :level="2"
              :styleLevel="1"
              title="Masters of the Snowy Peaks"
            />
            <p class="text">Cashmere mountain goats inhabit the northern regions of Central Asia and originate from the
              eponymous region of Kashmir, a former principality in the high Himalayas.
            </p>
            <p class="text">The pastures where we harvest cashmere are located on either side of the Tibetan plateau.
              Most of the cashmere fleece from the Hexi breed comes from the Chinese province of Gansu, while the most
              valuable cashmere from the Chantang breed is obtained in the Indian region of Ladakh.
            </p>
          </div>
        </div>
        <picture class="section-container__image">
          <source
            media="screen and (min-width: 1024px)"
            srcset="~/static/woolux/cashmere/peaks.jpg"
          />
          <img src="~/static/woolux/cashmere/peaks-m.jpg" alt="peaks" loading="lazy" />
        </picture>
      </div>
    </section>
    <section class="section-lightness">
      <div class="section-container">
        <div class="section-container__image-wrap">
          <picture class="section-container__image">
            <source
              media="screen and (min-width: 1024px)"
              srcset="~/static/woolux/cashmere/cow.jpg"
            />
            <img src="~/static/woolux/cashmere/cow-m.jpg" alt="cow" loading="lazy" />
          </picture>
          <picture class="section-container__image">
            <source
              media="screen and (min-width: 1024px)"
              srcset="~/static/woolux/cashmere/wool.jpg"
            />
            <img src="~/static/woolux/cashmere/wool-m.jpg" alt="wool" loading="lazy" />
          </picture>
        </div>
        <div class="section-container__text-wrapper">
          <div class="section-container__text">
            <SfHeading
              :level="2"
              :styleLevel="1"
              title="Natural Lightness"
            />
            <p class="text">We make our cashmere from the wool of nomadic goats. They don’t have a lot of fat, so a thin
              long undercoat grows under the wool on the lower part of the abdomen. This undercoat is used in the making
              of cashmere. The thickness of cashmere hair is only 15-18 micrometers, which is 2.5-3 times thinner than
              human hair.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section-touch">
      <div class="section-container left">
        <div class="section-container__text-wrapper">
          <div class="section-container__text">
            <SfHeading
              class="mw-240"
              :level="2"
              :styleLevel="1"
              title="The Magic of Light Touch"
            />
            <p class="text">Cashmere is considered to be synonymous with tactility. Its softness is only comparable to
              silk. This unique tactile experience is caused by the fineness of cashmere hair and the characteristics of
              the breeds of goats whose undercoat is sheared on the farms of our partners.
            </p>
          </div>
        </div>
        <picture class="section-container__image">
          <source
            media="screen and (min-width: 1024px)"
            srcset="~/static/woolux/cashmere/touch.jpg"
          />
          <img src="~/static/woolux/cashmere/touch-m.jpg" alt="touch" loading="lazy" />
        </picture>
      </div>
    </section>
    <section class="section-warmth">
      <div class="section-container">
        <picture class="section-container__image">
          <source
            media="screen and (min-width: 1024px)"
            srcset="~/static/woolux/cashmere/warmth.jpg"
          />
          <img src="~/static/woolux/cashmere/warmth-m.jpg" alt="artisans" loading="lazy" />
        </picture>
        <div class="section-container__text-wrapper">
          <div class="section-container__text">
            <SfHeading
              :level="2"
              :styleLevel="1"
              title="The Care of Warmth"
            />
            <p class="text">Traditionally, any wool is valued primarily for the warmth it provides. Like all natural
              fibers, the structure of cashmere fibers is unique, although the structure of any wool is similar. It
              consists of numerous microscopic air pockets which retain warm air inside.
            </p>
            <p class="text">The regions where our partner companies are located are characterized by a cold continental
              climate. In this climate, whether it is northern China or the highlands of northern India, roaming goats
              have adapted to survival over the centuries by growing thicker and warmer wool.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section-harvest">
      <div class="section-container left">
        <div class="section-container__text-wrapper">
          <div class="section-container__text">
            <SfHeading
              class="section-harvest__title"
              :level="2"
              :styleLevel="1"
              title="Spring Harvest"
            />
            <p class="text">
              Cashmere is sheared or combed by hand in the spring, during the molting of goats. One goat produces about
              150-200 grams of raw cashmere per year. After cleaning and processing, only 80-120 grams remain.
            </p>
            <p class="text">We are responsible for ensuring that the spring cashmere harvest begins only after warm
              daily temperatures are established in the collection regions in order not to expose the trimmed animals to
              the cold.
            </p>
          </div>
        </div>
        <picture class="section-container__image">
          <source
            media="screen and (min-width: 1024px)"
            srcset="~/static/woolux/cashmere/harvest.jpg"
          />
          <img src="~/static/woolux/cashmere/harvest-m.jpg" alt="harvest" loading="lazy" />
        </picture>
      </div>
    </section>
    <section class="section-artisans">
      <div class="section-container">
        <picture class="section-container__image">
          <source
            media="screen and (min-width: 1024px)"
            srcset="~/static/woolux/cashmere/artisans.jpg"
          />
          <img src="~/static/woolux/cashmere/artisans-m.jpg" alt="artisans" loading="lazy" />
        </picture>
        <div class="section-container__text-wrapper">
          <div class="section-container__text">
            <SfHeading
              class="mw-240"
              :level="2"
              :styleLevel="1"
              title="Traditions of artisans"
            />
            <p class="text">The yarn is dyed and woven exclusively by hand because of its special fineness. It usually
              takes
              from one and a half to four weeks.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section-goat">
      <div class="section-goat__container">
        <p class="text">
          The inhabitants of snow-covered peaks and plains share the wealth bestowed upon them by nature for generations
          with us.
        </p>
        <p class="text">Today, we use these resources for the benefit of ourselves and those we love. For those who we
          care for just
          as nature cares for us.</p>
      </div>
    </section>
    <LazyHydrate when-visible>
      <section class="section-products">
        <div class="section-products__container">
          <SfHeading
            class="section-products__title"
            :level="3"
            :styleLevel="3"
            :title="productTitle"
          />
          <div class="products">
            <SfProductCard
              v-for="(product, index) in products"
              :key="index"
              class="section-products__product"
              image-tag="img"
              :image-width="200"
              :image-height="200"
              :regular-price="$fc(product.price_range.minimum_price.final_price.value)"
              :image="product.image.url"
              :alt="productGetters.getName(product)"
              :title="productGetters.getName(product)"
              :link="localePath(getProductPath(product))"
              :tag_line="productGetters.getTagLine(product)"
              :wishlist-icon="false"
            />
          </div>
        </div>
      </section>
    </LazyHydrate>
  </div>
</template>

<script>
import { useApi, useProduct } from '~/composables';
import { onMounted, ref, useAsync } from '@nuxtjs/composition-api';
import { CashmereQuery } from '../queries/cashmere';
import { SfHeading, SfLink, SfProductCard } from '~/components';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import LazyHydrate from 'vue-lazy-hydration';
import useBannerAnimation from '../composables';

export default {
  name: 'Cashmere',
  components: {
    SfProductCard,
    SfHeading,
    SfLink,
    LazyHydrate
  },
  setup() {
    const {query} = useApi();
    const {getProductPath} = useProduct();
    const productTitle = 'Embrace a thousand years of nature\'s gifts, and experience the natural cashmere fiber that has been concentrated in Togas products.';
    const products = ref([]);

    useAsync(async () => {
      try {
        const productsResponse = await query(CashmereQuery);
        products.value = productsResponse.data.products.items;
      }
      catch (error) {
        console.error('Error in Cashmere.vue:', error);
      }
    });

    onMounted(() => {
      if(process.client) {
        useBannerAnimation();
      }
    });

    return {
      getProductPath,
      productTitle,
      products,
      productGetters,
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/Woolux.scss";
</style>
